import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RouteName } from "../RouteName";
import HomePage from "./common/homepage";
import Login from "./common/loginPage";
import Auth from "./common/Auth";
import AdminSideMenu from "./SuperAdmin/AdminSideBar";
import NavBarDashbord from "./common/NavBarDashbord";
import SidePane from "./common/SidePane";
import { MenuContext } from "./context/MenuContext";
import VerifyRole from "./common/VerifyRole";
import { Role } from "../models/Role";
import ContentLayout from "./common/ContentLayout";
import Content from "./common/Content";
import ViewNotes from "./SuperAdmin/ViewNotes";


const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  return (
    <Router>
      <Switch>
        <Route path={RouteName.LOGIN}>
          <Login />
        </Route>

        <Route path={RouteName.NOTETABLE}>
            <HomePage />
          </Route>

        <Route path="/">
          <Auth>
            <MenuContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
              <div className="bg-gray-100" style={{ display: "flex", flexDirection: "column", position: "absolute", width: "100%", height: "100%" }}>
                <Router>
                  <Switch>  
                    <Route path="/system-admin">
                      <VerifyRole allow={Role.SUPER_ADMIN}>
                        <SystemAdminDashbordRouter />
                      </VerifyRole>
                    </Route>
                  </Switch>
                </Router>
              </div>
            </MenuContext.Provider>
          </Auth>
        </Route>
      </Switch>
    </Router>
  );
};


//  System Admin  Dashbord
const SystemAdminDashbordRouter: React.FC = () => {
  return (
    <ContentLayout>
      <NavBarDashbord />
      <Router>
        <SidePane>
          <AdminSideMenu/>
        </SidePane>
        <Content>
          <Switch>
            <Route path={RouteName.ADMIN_VIEW_INSTANCE}>
              <ViewNotes/>
            </Route>
          </Switch>
        </Content>
      </Router>
    </ContentLayout>
  );
};
export default App;

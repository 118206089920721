import axios from "axios";
import { InstanceValue, Notes, NoteValues } from "../models/NoteValues";
import { AppResponse } from "../models/Response";
import { Rows, RowValues } from "../models/RowValues";

import { Util } from "../Util";

export class CommonService {
  public static async getAllNotes(id: string): Promise<AppResponse<Notes>> {
    const url = Util.apiPublicUrl(`get/notes/${id}`);

    return await axios.get<Partial<Notes>, AppResponse<Notes>>(url);
  }

  public static async saveNote(data: Partial<NoteValues>, unicId: string): Promise<AppResponse<NoteValues>> {
    const url = Util.apiPublicUrl(`createNote/${unicId}`);

    return await axios.post<Partial<NoteValues>, AppResponse<NoteValues>>(url, data);
  }

  public static async addInstanceNote(data: Partial<InstanceValue>): Promise<AppResponse<InstanceValue>> {
    const url = Util.apiPublicUrl("update/note");

    return await axios.post<Partial<InstanceValue>, AppResponse<InstanceValue>>(url, data);
  }

  public static async getAllRows(id: string): Promise<AppResponse<Rows>> {
    const url = Util.apiPublicUrl(`get/rows/${id}`);

    return await axios.get<Partial<Rows>, AppResponse<Rows>>(url);
  }

  public static async saveRow(data: Partial<RowValues>, unicId: string): Promise<AppResponse<RowValues>> {
    const url = Util.apiPublicUrl(`createRow/${unicId}`);

    return await axios.post<Partial<RowValues>, AppResponse<RowValues>>(url, data);
  }

}

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { RouteName } from "../../RouteName";
import "../assets/css/style1.css";
import "../assets/css/core.css";
import "../assets/css/icon-font.css";
import { MenuContext } from "../context/MenuContext";



const AdminSideMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  return (
    <div className={isMenuOpen ? 'left-side-bar open movingCompany' : 'left-side-bar movingCompany'}>
      <div className="brand-logo">

        <div className="close-sidebar" onClick={toggleMenu}>
          <i className="ion-close-round"></i>
        </div>
      </div>

      <div className="menu-block scrollSideBar">
        <div className="sidebar-menu">
          <ul id="accordion-menu">
            <li>
              <NavLink to={RouteName.ADMIN_VIEW_INSTANCE} onClick={toggleMenu} className={location.pathname == RouteName.ADMIN_VIEW_INSTANCE ? "dropdown-toggle no-arrow" : "dropdown-toggle no-arrow"}>
                <span className="micon dw dw-table"></span>
                <span className="mtext">All Instances</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSideMenu;

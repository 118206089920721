import axios from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { User, UserData } from "../models/User";
import { SystemAdmin } from "../models/SystemAdmin";
import { InstanceValue } from "../models/NoteValues";


export class AdminService {
    public static async getAllInstances(): Promise<AppResponse<InstanceValue[]>> {
        const url = Util.apiAuthUrl(`get/instances`);

        return await axios.get<Partial<InstanceValue>, AppResponse<InstanceValue[]>>(url);
    }

    public static async AddInstance(): Promise<AppResponse<InstanceValue>> {
        const url = Util.apiAuthUrl("add/note");

        return await axios.post<Partial<InstanceValue>, AppResponse<InstanceValue>>(url);
    }

    public static async DeleteInstance(unicId: string): Promise<AppResponse<InstanceValue>> {
        const url = Util.apiAuthUrl(`deleteInstance/${unicId}`);

        return await axios.delete<Partial<InstanceValue>, AppResponse<InstanceValue>>(url);
    }

    public static async ClearInstance(unicId: string): Promise<AppResponse<InstanceValue>> {
        const url = Util.apiAuthUrl(`clear/${unicId}`);

        return await axios.post<Partial<InstanceValue>, AppResponse<InstanceValue>>(url);
    }
}